import {CommonModel} from "@/model/CommonModel";
import {getAvgScoreOfClass, getAvgScoreOfSchool, getAvgScoreOfStudent, getList} from "@/api/erp/ExpQuestionnaireApi";
import axios from "axios";
import {API_URL_ERP, API_URL_EXP_CONSUMER} from "@/model/ConfigModel";
import {msg_err} from "@/utils/ele_component";

/**
 * ExpQuestionnaireModel
 */
class ExpQuestionnaireModel {
  // 获取不分页列表
  static async getList(document) {
    let [data] = await getList(document);
    return data.data;
  }

  // 获取课外知识平均分-学校
  static async getAvgScoreOfSchool(schoolId,accountList,account,startTime,endTime) {
    let [data] = await getAvgScoreOfSchool({
      schoolId,
      accountList,
      account,
      startTime,
      endTime
    });
    return data.data;
  }

  // 获取课外知识平均分-班级
  static async getAvgScoreOfClass(schoolId,accountList,account,startTime,endTime) {
    let [data] = await getAvgScoreOfClass({
      schoolId,
      accountList,
      account,
      startTime,
      endTime
    });
    return data.data;
  }

  // 获取课外知识平均分-个人
  static async getAvgScoreOfStudent(schoolId,accountList,account,startTime,endTime) {
    let [data] = await getAvgScoreOfStudent({
      schoolId,
      accountList,
      account,
      startTime,
      endTime
    });
    return data.data;
  }

  // 导入调查表
  static import(file, schoolId) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('file', file)
      axios.create({
        baseURL: API_URL_ERP
      }).request({
        url: `/v1/exp/questionnaire/import?schoolId=${schoolId}`,
        method: 'post',
        headers: {'Content-Type': 'multipart/form-data'},
        data: formData
      }).then(response => {
        if (response.status === 200) {
          if (response.data.code === "000000") {
            resolve(true)
          } else {
            msg_err(response.data.msg)
            resolve(response.data.msg)
          }
        } else {
          msg_err(response.data.msg)
          resolve(response.data.msg)
        }
      })
    })
  }
}

export {ExpQuestionnaireModel}
