import {request_async} from "@/utils/requestAsync";
import {API_URL_ERP} from "@/model/ConfigModel";

// 获取列表-不分页
export async function getList(data) {
  return request_async(API_URL_ERP + `/v1/exp/questionnaire/list`, "post_body", data);
}

// 获取课后知识-平均分-学校
export async function getAvgScoreOfSchool(data) {
  return request_async(API_URL_ERP + `/v1/exp/questionnaire/avgScoreOfSchool`, "post_json", data);
}

// 获取课后知识-平均分-班级
export async function getAvgScoreOfClass(data) {
  return request_async(API_URL_ERP + `/v1/exp/questionnaire/avgScoreOfClass`, "post_json", data);
}

// 获取课后知识-平均分-学生
export async function getAvgScoreOfStudent(data) {
  return request_async(API_URL_ERP + `/v1/exp/questionnaire/avgScoreOfStudent`, "post_json", data);
}

// 获取学校的调查表数量
export async function getSchoolQuestionnaireCount(data) {
  return request_async(API_URL_ERP + `/v1/exp/questionnaire/schoolQuestionnaireCount`, "get", data);
}
